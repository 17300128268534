import {header} from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import './Header.css'

const Header = () => {
    const {homepage, title} = header

    return (
        <>
            <img src="/curve_1.svg" alt="Curve" className="curve" />
            <header className='header center'>
                <h3>
                    {homepage ? (
                        <a href={homepage} className='link'>
                            {title}
                        </a>
                    ) : (
                        title
                    )}
                </h3>
                <Navbar/>
            </header>
        </>
    )
}

export default Header

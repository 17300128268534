import {useContext} from 'react'
import TagManager from "react-gtm-module/dist/TagManager";
import {ThemeContext} from './contexts/theme'
import Header from './components/Header/Header'
import About from './components/About/About'
import Projects from './components/Projects/Projects'
import Skills from './components/Skills/Skills'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import './App.css'

const App = () => {
    const [{themeName}] = useContext(ThemeContext)

    const tagManagerArgs = {
        gtmId: 'GTM-TTXZ52M'
    }

    TagManager.initialize(tagManagerArgs)
    return (
        <div id='top' className={`${themeName} app`}>
            <Header/>

            <main>
                <About/>
                <Projects/>
                <Skills/>
                <Contact/>
            </main>

            <ScrollToTop/>
            <Footer/>
        </div>
    )
}

export default App

import {contact} from '../../portfolio'
import './Contact.css'

const Contact = () => {
    if (!contact.email) return null

    return (
        <section className='section contact center' id='contact'>
            <h2 className='section__title'>Contact</h2>
            <span className="interest">
                Need a freelancer or interested in working with me?
            </span>
            <a href={`mailto:${contact.email}`}>
                <span type='button' className='btn btn--outline'>
                  Hire me!
                </span>
            </a>
        </section>
    )
}

export default Contact

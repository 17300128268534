const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '/',
  title: 'Sabila .',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Sabila',
  role: 'Fullstack Enthusiast',
  description:
    'Software engineer with two years experience design and executing solution for complex technology requirements. Interested to explore and dedicated to developing software using modern Javascript technology and PHP',
  resume: 'https://example.com',
  social: {
    linkedin: 'https://linkedin.com/in/sabila-auliya-chaq',
    github: 'https://github.com/sabilaac',
    playstore: 'https://play.google.com/store/apps/developer?id=SAC+Studio',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Jadwalku',
    description:
      'Applications that can schedule student activities to be more organized in making decisions and daily notes',
    stack: ['Android', 'Java'],
    download: 'https://play.google.com/store/apps/details?id=com.sabila.scheduler'
  },
  {
    name: 'RileksLagi ',
    description:
      'An application that has features in doing muscle relaxation and meditation using Javanese, Sundanese and Balinese music arrangements and available countdown time and pre-test and post-test after relaxation',
    stack: ['Android', 'Java'],
    download: 'https://play.google.com/store/apps/details?id=com.sacstudio.rilekslagi',
  },
  {
    name: 'Conflict Management Style ',
    description:
        'Contains the discovery of conflict management styles for students in schools through an android application',
    stack: ['Android', 'Java'],
    download: 'https://play.google.com/store/apps/details?id=com.sabilaac.cms',
  },
  {
    name: 'Dupan - Daur Hidup Hewan',
    description:
        'Android game designed for elementary school students to get to know the life cycle of animals',
    stack: ['Android', 'Java'],
    download: 'https://play.google.com/store/apps/details?id=com.sabilaac.dupan',
  },
  {
    name: 'ASRI',
    description:
        'Desktop application contains instructions for making fertilizers, countdowns, and printing the procedures for the fertilizers that have been selected. This product won the MAGE Competition Season 1 event at ITS Surabaya',
    stack: ['VB.NET'],
    download: 'https://drive.google.com/file/d/0B_jfUoY7VxaLZ2VvbW1KWUVHNUE/view?usp=sharing&resourcekey=0-PXAha_EIRrvG2Tp_m3kRAA',
  },
  {
    name: 'CantikID',
    description:
        'Provide services for selling products, beauty salon booking, treatments booking, barbershops booking and online beauty consulting services with expert doctors',
    stack: ['SASS', 'SvelteJS', 'Postgree', 'MongoDB','NodeJS'],
    livePreview: 'https://www.cantikid.com',
  },
  {
    name: 'E-Family Counseling ',
    description:
      'Applications that have functions such as course institutions and have mentors and course participants. Participants can also take courses with videos, consult with mentors and take online webinars',
    stack: ['MySQL', 'Codeigniter', 'SocketIO'],
    livePreview: 'https://www.efamilycounseling.id',
    private: true,
    reason: 'Domain temporarily down'
  },
  {
    name: 'Mas AL ',
    description:
         'Alternative assessment management to group student assessments regularly and easily accessible',
    stack: ['MySQL', 'Codeigniter'],
    livePreview: 'https://www.mas-al.id',
    private: true,
    reason: 'Domain temporarily down'
  },
  {
    name: 'Mertojaya Catering',
    description:
        'Landing website that provides catering equipment rental services for weddings, meetings and events. There is also a CMS to manage equipment for rent',
    stack: ['MySQL', 'Codeigniter'],
    livePreview: 'https://www.mertojaya-catering.id',
    private: true,
    reason: 'Domain temporarily down'
  },
  {
    name: 'Energy Monitoring System',
    description:
      'Create an energy monitoring solution for company or factory needs that can be controlled remotely and can send alarm messages to operators for follow-up',
    stack: ['VueJS', 'Grafana', 'NodeJS', 'Mongo DB', 'Prometheus', 'ExpressJS', 'WebsSocket'],
    private: true,
    reason: 'Private only'
  },
  {
    name: 'CMMS',
    description:
      'Optimizes maintenance operations by centralizing and automating tasks such as scheduling, work orders, and tracking maintenance history for enhance overall operational efficiency.',
    stack: ['VueJS', 'MongoDB', 'NodeJS', 'Express JS'],
    private: true,
    reason: 'Private only'
  },
  {
    name: 'Pencakar',
    description:
      'A game to support career planning for 10th grade high school students. It utilizes augmented reality technology to present learning materials, offering students a unique experience by showcasing 3D character features on their smartphone screens.',
    stack: ['Unity', 'Vuforia'],
    download: 'https://play.google.com/store/apps/details?id=com.sacstudio.pencakar',
  },
  {
    name: 'Kerjapedia',
    description:
      'A platform that provides freelance job opportunities between providers and seekers.',
    stack: ['Flutter', 'MongoDB', 'NodeJS', 'FastifyJS', 'SocketIO'],
    ongoing: true,
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Node JS',
  'Express JS',
  'Fastify JS',
  'Mongo DB',
  'Svelte JS',
  'React JS',
  'Vue JS',
  'Codeigniter',
  'Flutter',
  'Unity',
  'CSS3',
  'HTML5',
  'MySQL',
  'Linux',
  'Git',
  'Grafana',
  '.NET',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'sabilamovie@gmail.com',
}

export { header, about, projects, skills, contact }

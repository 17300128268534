import './Footer.css'

const Footer = () => (
    <>
        <footer className='footer'>
          <span>
              &copy; Sabila Studio
          </span>
        </footer>
        <img src="/curve_2.svg" alt="Curve" className="curve bottom"/>
    </>

)

export default Footer

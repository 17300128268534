import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import ShopIcon from '@material-ui/icons/Shop'
import { about } from '../../portfolio'
import './About.css'

const About = () => {
  const { name, role, description, resume, social } = about

  return (
    <div className='about center'>
            <img className="photo" src='https://avatars.githubusercontent.com/u/54090521?v=4' alt='profile' width="150" height="150" />
      {name && (
        <h1>
          Hi, I am <span className='about__name'>{name}.</span>
        </h1>
      )}

      {role && <h2 className='about__role'>A {role}.</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
              >
                <LinkedInIcon />
              </a>
            )}

              {social.playstore && (
                  <a
                      href={social.playstore}
                      aria-label='playstore'
                      className='link link--icon'
                  >
                      <ShopIcon />
                  </a>
              )}
          </>
        )}
      </div>
    </div>
  )
}

export default About

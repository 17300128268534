import uniqid from 'uniqid'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import './ProjectContainer.css'
import {CloudDownload, WatchLater} from "@material-ui/icons";
import {contact} from "../../portfolio";

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <h3>{project.name}</h3>

    <p className='project__description' >{project.description}</p>
    {project.stack && (
      <ul className='project__stack'>
        {project.stack.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

      <div className="action">
          {project.sourceCode && (
              <a href={project.sourceCode}>
                <span className='btn btn--outline mini'>
                  <GitHubIcon /> Github
                </span>
              </a>
              // <a
              //     href={project.sourceCode}
              //     aria-label='source code'
              //     className='link link--icon'
              // >
              //     <GitHubIcon />
              // </a>
          )}

          {project.download && (
              <a href={project.download}>
                <span className='btn btn--outline mini'>
                    <CloudDownload />
                    <span>
                        Download
                    </span>

                </span>
              </a>
              // <a
              //     href={project.download}
              //     aria-label='download'
              //     className='link link--icon'
              // >
              //     <CloudDownload />
              // </a>
          )}
    
          {project.livePreview && (
              <a href={`${!project.private ? project.livePreview : '#'}`}>
                <span className={`btn btn--outline mini  ${project.private ? 'disabled' : ''}`}>
                  <LaunchIcon /> Open
                </span>
              </a>
          )}
          {project.ongoing && (
              <b className='ongoing'>
                  <WatchLater />
                  Ongoing
              </b>
          )}
          {project.reason && (
              <small className='reason'>
                  {project.reason}
              </small>
          )}
      </div>
  </div>
)

export default ProjectContainer
